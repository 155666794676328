<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Complementos
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <!-- <v-spacer/> -->
                    <!-- <v-btn color="primary" fab small @click="openCadastro">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="descricao"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 180"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <!-- <template v-slot:item.valor="{ item }">
                                <span style="color: green;">R$ {{item.valor}}</span>
                            </template> -->
                            <template v-slot:item.estoque="{ item }">
                                <div v-if="showEstoque">
                                    <v-text-field
                                        v-if="item.controle_estoque"
                                        v-model="item.estoque"
                                        v-mask="['#####']"
                                        outlined
                                        dense
                                        hide-details
                                        @click.stop=""
                                        style="width: 100px;"
                                    >
                                        <template #append>
                                            <v-btn
                                                @click.stop="updateEstoque(item)"
                                                icon
                                                small
                                                style="margin: -2px -4px 0 0;"
                                            >
                                                <v-icon small>mdi-content-save</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                    <span v-else>Desabilitado</span>
                                </div>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <v-switch
                                    v-model="item.status"
                                    inset
                                    hide-details
                                    dense
                                    true-value="ativo"
                                    false-value="inativo"
                                    @click.stop
                                    @change="onUpdateStatus(item)"
                                    style="margin-top: -4px;"
                                />
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-btn
                                    @click.stop="excluir(item.id)"
                                    :disabled="!isAdmin"
                                    color="red"
                                    icon
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-content>
            <CadastroComplementos
                :visible="visible"
                :formData="form"
                @onCadastro="onCadastro"
                @close="close"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import CadastroComplementos from '@/pages/cadastro/Complementos';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Complementos',

    components: {
        BaseContainer,
        CadastroComplementos,
    },

    data: () => ({
        visible: false,
        search: '',
        loading: true,
        dados: [],
        form: {},
        headers: [
            { text: 'Descrição', value: 'descricao' },
            { text: 'Categoria', value: 'categoria.descricao' },
            { text: 'Ativo', value: 'status' },
            { text: 'Excluir', value: 'action', sortable: false, align: 'end' },
        ],
    }),

    mounted() {
        if (this.showEstoque) {
            this.headers.splice(2, 0, { text: 'Estoque', value: 'estoque' });
        }

        this.consultar();
    },

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),

        isAdmin() {
            return this.usuario.admin;
        },

        showEstoque() {
            return this.$vuetify.breakpoint.mdAndUp && this.configuracoes.controle_estoque;
        },
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        consultar() {
            this.loading = true;
            this.dados = [];
            this.$http.get('complementos').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.loading = false;
            });
        },

        close() {
            this.visible = false;
            this.form = {};
        },

        // openCadastro() {
        //     this.form = {
        //         status: 'ativo',
        //         ativo: true,
        //         descricao: '',
        //         detalhes: '',
        //         categoria: '',
        //         valor: '',
        //     };
        //     this.visible = true;
        // },

        onCadastro() {
            this.close();
            this.consultar();
        },

        onUpdateStatus(complemento) {
            this.$http.put(`complementos/${complemento.id}`, complemento).then(() => {
                this.notify('Atualizado com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        editar(row) {
            if (!this.isAdmin) {
                return;
            }
            this.form = row;
            this.visible = true;
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.delete(`complementos/${id}`).then(() => {
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                });
            }
        },

        updateEstoque({ id, estoque }) {
            this.$http.put(`complementos/${id}`, { estoque }).then(() => {
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },
    },
}
</script>
